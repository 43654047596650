<template>
    <div>
        <FormView @submit="handleSubmit">
            <div class="row">
                <div class="col-12">
                    <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                        {{msg}}
                    </div>
                </div>
                <div class="col-12 group-form" id="table">
                    <div class="tr">
                        <div class="td">
                            <br/>
                            <img :src="me && me.profile_image" class="img-profile">
                        </div>
                        <div class="td w-100 hide-label">
                            <TextArea
                                :label="$t('inquiry.contact_detail')"
                                name="content"
                                placeholder="メッセージを記載してください"
                                v-model="inquiry.content"
                                />
                            <AttachmentFile
                                @change="handleChooseFile"
                                :removeFile="removeFile"
                                :icon="true"
                            />
                       </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <button 
                        type="submit" class="btn btn-submit text-center"
                        :disabled="isSending">
                        {{$t('inquiry.send')}} {{ isSending? "...": "" }}
                    </button>
                </div>
            </div>
        </FormView>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "FromReply",
    data() {
        return {
            id: this.inquiryId,
            inquiry: {
                content: "",
                attachment: ""
            },
            isSending: false,
            msgType: "danger",
            msg: "",
            file: null,
            removeFile: false
        };
    },
    props: {
        inquiryId: { default: null },
        me:  { default: null }
    },
    methods: {
        ...mapActions({
            replyInquiry: "inquiry/replyInquiry",
            uploadFile: "upload/uploadFile"
        }),
        async handleSubmit(){
            if(this.isSending)return;
            this.isSending = true;
            if(this.file){
                let attachment = await this.uploadInquiryFile();
                this.isSending = false;
                if(!attachment){
                    return;
                }
                this.inquiry = {
                    ...this.inquiry,
                    attachment: attachment
                }
            }
            
            await this.replyInquiry({
                id: this.id,
                data: this.inquiry,
                cb: (response) => {
                    const { data, meta } = response;
                    if(meta && meta.code == 200 && data.status !== false){
                        this.$emit("change", true);
                        this.msg = "";
                        this.inquiry = {
                            content: ""
                        }
                        this.removeFile = true;
                    }else{
                        if(data.errors){
                            let field = Object.keys(data.errors)[0];
                            this.msg = this.$t(data.errors[field][0], {
                                _field_: "お問い合わせ詳細"
                            });
                        }else{
                            if(data && data.message){
                                this.msg = data.message
                            }else{
                                this.msg = this.$t("top_page.inquiry.failed");
                            }
                        }
                        
                        this.msgType = "danger"
                    }
                }   
            });
            this.isSending = false;
        },
        handleChooseFile(file){
            this.file = file;
            this.removeFile = false;
        },
        async uploadInquiryFile(){

            let path = "";
            await this.uploadFile({
                file: this.file,
                path: "inquiry",
                cb: (response) => {
                    path = response.path;
                    if(!path){
                        let { data, meta } = response;
                        if(data.errors){
                            let field = Object.keys(data.errors)[0];
                            this.msg = this.$t(data.errors[field][0], {
                                _field_: "お問い合わせ詳細"
                            });
                        }else{
                            if(data && data.message){
                                this.msg = data.message
                            }else{
                                this.msg = this.$t("top_page.inquiry.failed");
                            }
                        }
                        this.msgType = "danger"
                    }
                }
            });
            return path;
        }
    },
    watch: {
        inquiryId(id){
            this.id = id;
        }
    }
}
</script>