<template>
    <div>
        <div class="ml-2">
            <breadcrumb :breadcrumb="breadcrumbItems"/>
        </div>
        <div>
            <div class="main">

                <div class="inquiry-detail">
                    <div class="title">
                        <h3>{{$t("inquiry.detail")}}</h3>
                    </div>
                    <div class="body">
                        <div class="card">
                            <div class="card-header">
                                <div class="info" v-if="detail.lesson.level">
                                    {{ $t(`lesson.${detail.lesson.level}`) }}
                                </div>
                                <h3 class="card-title">{{$t('inquiry.lesson')}}: <router-link class="black" :to="`/lesson-detail/${detail.lesson.id}`">{{ detail.lesson.title }}</router-link></h3>
                            </div>
                            <div class="card-body" id="table">
                                <div class="tr">
                                    <div class="td w-100 text-center">
                                        <div class="cimg">
                                            <img
                                                :src="detail.groupProfile.profile_image"
                                                :alt="detail.groupProfile.name"
                                            />
                                        </div>
                                        <h5 class="card-title text-center">
                                            <router-link class="black" v-if="detail.groupProfile.role == 'teacher'" :to="`/teacher/${detail.groupProfile.member_id}/profile`">
                                                {{detail.groupProfile.name}}
                                            </router-link>
                                            <router-link class="black" v-else :to="`/student/${detail.groupProfile.member_id}/profile`">
                                                {{detail.groupProfile.name}}
                                            </router-link>
                                        </h5>
                                    </div>
                                </div>
                                <div class="tr">
                                    <div class="td w-100">
                                        <div class="text-title">
                                            {{$t('inquiry.subject')}}
                                        </div>
                                        <div class="text-descr">
                                            {{ detail.subject }}
                                        </div>
                                        <div class="text-title">
                                            {{$t('inquiry.content')}}
                                        </div>
                                        <div class="text-descr">
                                            {{ detail.content }}
                                        </div>
                                        <div class="text-title">
                                            {{$t('inquiry.teacher')}}
                                        </div>
                                        <div class="text-descr">
                                            {{ detail.teacher.name }}
                                        </div>
                                        <div class="text-title">
                                            {{$t('inquiry.student')}}
                                        </div>
                                        <div class="text-descr">
                                            {{ detail.replier.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="list">
                            <div class="summary">
                                <div class="total">
                                    <label>コメント</label>
                                    <span>({{reply.total}})</span>
                                </div>
                                <!-- <div class="view-all">
                                    <label>表示：</label>
                                    <button @click="loadReplyAll">すべて表示</button>
                                </div> -->
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="load-more" v-if="reply.hide">
                                                <b @click="handleMoreReplies" :disabled="reply.loading">
                                                    {{ `${reply.hide} ${$t("inquiry.comment")} ${reply.loading? '...': ''}` }}
                                                </b>
                                            </div>
                                            <ListReply
                                                :replies="replies"
                                            />
                                        </div>
                                        <div class="col-12 pt-2 mb-3">
                                            <FormReply
                                                :inquiryId="detail.inquiry_id"
                                                :me="detail.me"
                                                @change="handleMoreReplies"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MetaTags 
            title="お問い合わせリスト｜ResMom相談online"
            description="リセマム相談オンラインのお問い合わせリストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FormReply from './components/form-reply.vue';
import ListReply from './components/list-reply.vue';
export default {
    name: "InquiryDetail",
    components: {
        FormReply,
        ListReply
    },
    data() {
        return {
            id: null,
            replies: [],
            reply: {
                page: 1,
                limit: 5,
                hide: 0,
                loading: false,
                total: 0
            },
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: '/my-page'
                },
                {
                    title: this.$t("menu.breadcrumb.inquiry"),
                    link: "/my-page/inquiry"
                },
                {
                    title: this.$t("menu.breadcrumb.inquiry_detail"),
                    link: '#'
                },
            ]

        };
    },
    async created() {
        this.id = this.$route.params.id;
        await this.getInquiry({
            id: this.id
        });
        this.listReplies(this.id);
        this.listenChannelBroadcastEvent();
    },
    destroyed() {
        let memberId = this.getMemberId();
        this.echoLeave("inquiry." + this.id + ".member." + memberId)
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.id = this.$route.params.id;
                this.getInquiry({
                    id: this.id
                });
                this.handleMoreReplies(true);
            },
        },
    },
    computed: {
        ...mapGetters({
            detail: "inquiry/detail"
        })
    },
    methods: {
        ...mapActions({
            getInquiry: "inquiry/getInquiry",
            getInquiryReply: "inquiry/getInquiryReply",
        }),
        async listReplies(id){
            let { page, limit } = this.reply;
            await this.getInquiryReply({
                    id: id,
                    params: { 
                       limit,
                       page
                    },
                    cb: (response) => {
                        let { data, meta } = response;
                        if(meta && meta.code == 200){
                            let { pagination } = meta;
                            let _arr = data.reverse();
                            if(pagination.current == 1){
                                this.replies = _arr;
                            }else{
                                this.replies = _arr.concat(this.replies);
                            }
                            let hide = pagination.total - this.replies.length;
                            if(hide < 0){
                                hide = 0;
                            }
                            this.reply = {
                                ...this.reply,
                                hide,
                                loading: false,
                                total: pagination.total
                            }
                        }
                    }
                });
        },
        async handleMoreReplies(newComment=false){
            if(this.reply.loading)return;
            let { limit, page } = this.reply;
            if(newComment == true){
                page = 1;
                if(limit !== 5){
                    limit = 50;
                }
            }else{
                if(limit == 50){
                    page = page + 1;
                }
                limit = 50;
            }
            this.reply = {
                ...this.reply,
                limit,
                page,
                loading: true
            }
            await this.listReplies(this.id);
        },
        async loadReplyAll() {
            if(this.reply.loading)return;
            this.reply = {
                ...this.reply,
                limit: this.reply.total,
                page: 1,
                loading: true
            }
            await this.listReplies(this.id);
        },
        listenChannelBroadcastEvent() {
            let memberId = this.getMemberId();
            this.echo("inquiry."+this.id+".member."+memberId, ".created", (e) => {
                this.handleMoreReplies(true);
            });
        },
    },
}
</script>