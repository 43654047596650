<template lang="html">
    <div class="list-reply">
        <div  class="reply-item" id="table" v-for="(item, index) in replies" :key="index">
            <div class="tr">
                <div class="td">
                    <img :src="item.replier.profile_image" class="img-profile" />
                </div>
                <div class="td w-100">
                    <div class="item-title">
                        <router-link v-if="item.replier.role == 'teacher'" :to="'/teacher/' + item.replier.member_id + '/profile'">
                            <b>{{item.replier.name}}</b>
                        </router-link>
                        <router-link  v-else :to="'/student/' + item.replier.member_id + '/profile'">
                            <b>{{item.replier.name}}</b>
                        </router-link>
                        <button
                            @click="handleReportToAdmin(item.inquiry_id)"
                            v-if="item.allow_report"
                            >
                            {{$t('inquiry.report_to_admin')}}
                        </button>
                    </div>
                    <div class="card-text text-pre-line">
                        <p>{{item.content}}</p>
                        <a target="_blank" :href="item.attachment" v-if="item.attachment">
                            <div v-if="isImageUrl(item.attachment)">
                                <img class="comment-img" :src="item.attachment" />
                            </div>
                            <div v-else>
                                {{ getNameFormURL(item.attachment) }}
                            </div>
                        </a>
                    </div>
                    <div class="item-text text-right">
                        <label class="date">{{item.inquiry_date}}</label>
                    </div>
                </div>
            </div>
        </div>
        <Popup
            :isVisible="this.isVisible"
            :confirmButton="$t('inquiry.report.ok')"
            :cancelButton="$t('inquiry.report.cancel')"
            :isJustifyBetween="false"
            @cancel="actionCancel"
            @confirm="actionConfirm"
        >
            {{$t('inquiry.report.title')}}
        </Popup>
    </div>
</template>
<script>
export default {
    name: "ListReply",
    data(){
        return {
            isVisible: false,
            inquiryId: null
        }
    },
    props:{
        replies: []
    },
    methods: {
        actionCancel(){
            this.isVisible = false;
        },
        actionConfirm(){
            this.isVisible = false;
            this.$router.push(`/my-page/inquiry-report/${this.inquiryId}`);
        },
        handleReportToAdmin(id){
            this.inquiryId = id;
            this.isVisible = true;
        }
    }
}   
</script>